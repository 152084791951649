import './App.css';

function App() {
  return (
    <div>
    <br/>
    
    <a 
      style={{marginLeft: "5%", textDecoration: "none", textAlign: "left",  color: "#61dafb", fontSize:"18px"}} 
      href="https://twitter.com/scubgod"  
      target="_blank"
      rel="noopener noreferrer"
      >
        s c u b a
    </a>

    <div className="App">
        <h1>Projects</h1>

        <hr style={{width: "45%"}}/>
        <br/>
        <a
          className="App-link"
          href="https://mkts.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          mkts.io
        </a>
        <p>Market data application that provides sortable data for Cryptocurrencies, Stocks and Foreign Exhcange Currencies</p>
        <br/>
        <br/>
        <a
          className="App-link"
          href="https://ecostats.info"
          target="_blank"
          rel="noopener noreferrer"
        >
          EcoStats
        </a>
        <p>Market cap and volume historical data for 25+ Cryptocurrency Ecosystems. Datasets begin January 2022. Data displayed with line charts.</p>
        <br/>
        <br/>
        <a
          className="App-link"
          href="willsol.pdf"
          target="_blank"
        >
          EthWill
        </a>
        <p>Solidity Smart Contract that locks ones funds and distributes to beneficiaries at a specified date via the benefactor or a representative.<br/> A "psuedo will and testament"</p>
        <br/>
    </div>
    </div>
  );
}

export default App;
